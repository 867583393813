import React from "react"
import { graphql } from "gatsby"
import { IubendaPageQuery } from "../__generated__/IubendaPageQuery"
import DefaultLayout from "../components/layouts/DefaultLayout"
import { LanguageProvider } from "../context/language"
import { LayoutContextProvider } from "../context/contents/layoutContext"
import { RichTextField } from "../components/ui/molecules/Fields"
import CustomContainer from "../components/ui/atoms/CustomContainer"
import CustomTypography from "../components/ui/atoms/CustomTypography"
import { FadeIn } from "../components/ui/animations"
import { withPrismicPreview } from "../root/contents/prismic/preview"
import { PrismicPage } from "../root/contents/prismic/pages/PrismicPage"
import { makeStyles } from "@material-ui/core"
import { whenMobile } from "../utils/themeUtils"

interface Props {
  data: IubendaPageQuery
}

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(8),

    "& h1": {
      fontSize: theme.typography.h3.fontSize,
      lineHeight: theme.typography.h3.lineHeight,
      marginTop: theme.spacing(4),
    },
    "& h2": {
      fontSize: theme.typography.h4.fontSize,
      lineHeight: theme.typography.h4.lineHeight,
      marginTop: theme.spacing(4),
    },
    "& h3": {
      fontSize: theme.typography.h5.fontSize,
      lineHeight: theme.typography.h5.lineHeight,
    },
    "& h4": {
      fontSize: theme.typography.h5.fontSize,
      lineHeight: theme.typography.h5.lineHeight,
    },
    "& h5": {
      fontSize: theme.typography.h5.fontSize,
      lineHeight: theme.typography.h5.lineHeight,
    },
    "& h6": {
      fontSize: theme.typography.h6.fontSize,
      lineHeight: theme.typography.h6.lineHeight,
    },
    "& p": {
      fontSize: theme.typography.body1.fontSize,
      lineHeight: theme.typography.body1.lineHeight,
      marginTop: 0,
      marginBottom: 0,
    },

    [whenMobile(theme)]: {
      "& h1": {
        fontSize: theme.typography.h4.fontSize,
        lineHeight: theme.typography.h4.lineHeight,
      },
      "& h2": {
        fontSize: theme.typography.h5.fontSize,
        lineHeight: theme.typography.h5.lineHeight,
      },
      "& h3": {
        fontSize: theme.typography.h6.fontSize,
        lineHeight: theme.typography.h6.lineHeight,
      },
      "& h4": {
        fontSize: theme.typography.h6.fontSize,
        lineHeight: theme.typography.h6.lineHeight,
      },
      "& h5": {
        fontSize: theme.typography.subtitle1.fontSize,
        lineHeight: theme.typography.subtitle1.lineHeight,
      },
      "& h6": {
        fontSize: theme.typography.subtitle1.fontSize,
        lineHeight: theme.typography.subtitle1.lineHeight,
      },
    },
  },
}))

const IubendaPage = ({ data }: Props) => {
  const classes = useStyles()
  return (
    <LanguageProvider language={data.prismicIubendaPage?.lang as string}>
      <PrismicPage page={data.prismicIubendaPage}>
        <LayoutContextProvider>
          <DefaultLayout headerBackground="black" headerInline>
            <CustomContainer className={classes.container}>
              <FadeIn>
                <RichTextField
                  value={data.prismicIubendaPage?.data?.title}
                  variant="h1"
                  mt={8}
                  mb={8}
                />
                <CustomTypography
                  innerHtml={data.prismicIubendaPage?.data?.policy_body ?? ""}
                  mb={8}
                />
              </FadeIn>
            </CustomContainer>
          </DefaultLayout>
        </LayoutContextProvider>
      </PrismicPage>
    </LanguageProvider>
  )
}

export default withPrismicPreview(IubendaPage)

export const pageQuery = graphql`
  query IubendaPageQuery($id: ID!) {
    prismicIubendaPage(prismicId: { eq: $id }) {
      prismicId
      lang
      data {
        title {
          text
        }
        seo_title {
          text
        }
        seo_description {
          text
        }
        policy_body
      }
    }
  }
`
